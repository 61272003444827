.skin-yellow .main-header .logo {
  background-color: #3c4856;
}

.skin-yellow .main-header .logo:hover {
  background-color: #222d32;
}

.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  background-color: #222d32;
}

.skin-yellow .main-header .navbar .dropdown-menu li a:hover {
  background-color: #3c4856;
}

.skin-yellow .left-side, .skin-yellow .main-sidebar, .skin-yellow .wrapper {
  background-color: #3c4856;
}

.skin-yellow .sidebar-form input[type="text"] {
  color: #fff;
}

.skin-yellow .sidebar-form .btn, .skin-yellow .sidebar-form input[type="text"] {
  color: #fff;
  background-color: #222d32;
}

.skin-yellow .sidebar a {
  color: #fff;
}

.skin-yellow .sidebar a:hover {
  background-color: #222d32;
}

.skin-yellow .main-header li.user-header {
  background-color: #3c4856;
}

.skin-yellow .sidebar-menu > li.active > a {
  border-left-color: #52c4c0;
}

.skin-yellow .main-header .navbar {
  background-color: #52c4c0;
}

footer {
  border-top: 5px solid #52c4c0;
  background-color: #dbdbdb;
}

footer > div > div > div > a {
  color: black;
}

footer > div > div > div > a:hover {
  color: #52c4c0;
  text-decoration: none;
}

.content-wrapper {
  background-color: #f0f7f6;
}

.btn-eplace {
  background-color: #52c4c0;
  color: white;
}

.btn-eplace:hover {
  background-color: #47aba6;
  color: white;
}

.box.box-solid.box-eplace > .box-header {
  background-color: #52c4c0;
  color: white;
}

.box.box-solid.box-eplace {
  border: 1px solid #52c4c0;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.low_opacity {
  opacity: .65;
  filter: alpha(opacity=65);
}

.bg-white {
  background-color: #ffffff;
}

.text-dark-gray {
  color: #9fa0a9 !important;
}

.mt-1 {
  margin-top: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 1px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-1 {
  padding-top: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-1 {
  padding-left: 1px;
}

.pl-2 {
  padding-left: 2px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-1 {
  padding-right: 1px;
}

.pr-2 {
  padding-right: 2px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .pl-50 {
    padding-left: 1px;
  }
  .pr-50 {
    padding-right: 1px;
  }
}

@media (min-width: 768px) {
  .pl-50 {
    padding-left: 50px;
  }
  .pr-50 {
    padding-right: 50px;
  }
}

.fb-1 {
  font-size: 1.1em;
}

.fb-2 {
  font-size: 1.2em;
}

.fb-5 {
  font-size: 1.5em;
}

.fb-10 {
  font-size: 2em;
}

.fs-1 {
  font-size: 0.99em;
}

.fs-2 {
  font-size: 0.98em;
}

.fs-5 {
  font-size: 0.95em;
}

.fs-10 {
  font-size: 0.90em;
}

.padding-h-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-h-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.padding-h-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.padding-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-v-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-v-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.padding-v-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}

.table.dataTable.stripe tbody tr.even, table.dataTable.display tbody tr.even {
  background-color: #ffffff;
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #cbd6f1;
}

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #dbe3ff;
}

table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #cbe2f1;
}

table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #dbeeff;
}

table.dataTable thead th, table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

table.dataTable tfoot th, table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111;
}
