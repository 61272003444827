// Fondo del menu
.skin-yellow .main-header .logo {
  background-color: $leftNavColor;
}
.skin-yellow .main-header .logo:hover {
  background-color: $leftNavColorOver;
}

//Fondo del icono para reducir el menu - hover
.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  background-color: $leftNavColorOver;
}

.skin-yellow .main-header .navbar .dropdown-menu li a:hover {
  background-color: $leftNavColor;
}

// Fondo del menu izquierdo
.skin-yellow .left-side, .skin-yellow .main-sidebar, .skin-yellow .wrapper {
  background-color: $leftNavColor;
}

// Buscador
.skin-yellow .sidebar-form input[type="text"] {
  color: $leftLinksTextColor;
}
.skin-yellow .sidebar-form .btn, .skin-yellow .sidebar-form input[type="text"] {
  color: $leftLinksTextColor;
  background-color: $leftNavColorOver;
}

// Links del menu izquierdo
.skin-yellow .sidebar a {
  color: $leftLinksTextColor;
}
.skin-yellow .sidebar a:hover {
  background-color: $leftNavColorOver;
}

// Fondo de la foto del usuario en su perfil
.skin-yellow .main-header li.user-header {
  background-color: $leftNavColor;
}

// Rayita del menu seleccionado
.skin-yellow .sidebar-menu > li.active > a {
  border-left-color: $mainBrandColor;
}

// Fondo del encabezado
.skin-yellow .main-header .navbar {
  background-color: $mainBrandColor;
}