footer {
  border-top: 5px solid $mainBrandColor;
  background-color: $footerBackground;
}

footer>div>div>div>a {
  color: black;
}

footer>div>div>div>a:hover {
  color: $mainBrandColor;
  text-decoration: none;
}